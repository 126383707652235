import React from 'react';

const Header = ({ text, children }) => {
  return (
    <header>
      <h1>{text}</h1>
      {children}
    </header>
  );
};

export default Header;
