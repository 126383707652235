import React from 'react';
import * as style from "./index.module.sass";

const User = props => {
  return (
    <div className={style.user}>
      <img src={props.avatar} className={style.avatar} alt="" />
      <div className={style.description}>
        <h2 className={style.username}>{props.username}</h2>
        <p className={style.excerpt}>{props.excerpt}</p>
      </div>
    </div>
  );
};

export default User;
