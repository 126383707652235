import React from 'react';
import { Link } from 'gatsby';
import Header from '../components/Header';
import ListLink from '../components/ListLink';
import * as style from './desktop.module.sass';

const DesktopLayout = ({ children }) => {
  return (
    <div className={style.pageWrapper}>
      <Header text="Hello Gatsby!">
        <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
          <h3 style={{ display: `inline` }}>MySweetSite</h3>
        </Link>
        <ul style={{ listStyle: `none`, float: `right` }}>
          <ListLink to="/">Home</ListLink>
          <ListLink to="/about/">About</ListLink>
          <ListLink to="/my-files/">My Files</ListLink>
          <ListLink to="/contact/">Contact</ListLink>
        </ul>
      </Header>
      {children}
    </div>
  );
};

export default DesktopLayout;
